<template>
  <v-container class="quizShell">
    <v-card shaped class="quizCard">
      <div
        v-if="!customHeader"
        class="quizCard__header"
        :style="{
          backgroundColor: colorScheme || 'var(--v-primary-base)'
        }"
      >
        <div class="quizCard__header--questionCounter">
          <span class="font-weight-black white--text">
            {{
              $t('Question ') +
              currentQuestionNumber +
              $t(' of ') +
              totalNumberOfQuestions
            }}
          </span>
        </div>
        <div class="gameInfo">
          <div
            :class="{ 'gameInfo--scoreCardNormal': !$vuetify.breakpoint.xs }"
          >
            <div
              :style="{ textAlign: 'center' }"
              class="font-weight-black pl-2 white--text"
            >
              <v-icon class="ml-1 mr-1" color="white"
                >mdi-check-circle-outline</v-icon
              >
              <span>{{ correctCount }}</span>
              <v-icon class="ml-1 mr-1" color="white"
                >mdi-close-circle-outline</v-icon
              >
              <span>
                {{ wrongCount }}
              </span>
            </div>
          </div>
          <div
            class="pl-4"
            :class="
              $vuetify.breakpoint.xs
                ? 'gameInfo--settingsMobile'
                : 'gameInfo--settingsNormal'
            "
            :style="{
              backgroundColor: colorScheme || 'var(--v-primary-base)'
            }"
          >
            <LevelSelector
              v-if="levels"
              :levels="levels"
              :initialLevel="selectedLevel"
              @onSelect="restartWithSelectedLevel"
            ></LevelSelector>

            <HowToPlay
              :i18n_instructions_key="i18n_instructionsKey"
              :hideLabel="true"
              color="white"
              iconSize="x-large"
              class="mr-2"
            />
            <AudioPlayer
              v-if="showAudioSetting"
              hideButton="true"
              hideButtonName="true"
              onDarkBackground="true"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center white--text text-h6 pa-2"
        :style="{
          backgroundColor: colorScheme || 'var(--v-primary-base)'
        }"
      >
        {{ customHeader }}
      </div>

      <v-row no-gutters class="pa-2 mt-5 justify-center">
        <v-col style="text-align: center">
          <slot name="question"></slot>
        </v-col>
      </v-row>
      <div class="answerSlot pb-5">
        <v-row no-gutters class="pa-2 justify-center">
          <v-col style="text-align: center">
            <slot name="answer"></slot>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center" v-if="displaySubmit">
          <v-col class="text-center">
            <v-btn raised="true" color="primary" small @click="submitAnswer"
              >{{ $t('Submit Answer') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-if="displayPreviousQuestionBtn || displayNextQuestionBtn"
        >
          <v-col v-if="displayPreviousQuestionBtn">
            <v-btn
              raised="true"
              color="primary"
              small
              text
              @click="goToPreviousQuestion"
            >
              <v-icon dark> mdi-arrow-left-thin </v-icon>
              {{ $t('Previous') }}</v-btn
            >
          </v-col>
          <v-col align="right" v-if="displayNextQuestionBtn">
            <v-btn
              raised="true"
              color="primary"
              small
              text
              @click="goToNextQuestion"
              >{{ $t('Next') }} <v-icon dark> mdi-arrow-right-thin </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="soundEffects" class="ml-3 mr-3 d-flex justify-end">
          <RoundVolumeControl
            min="0"
            max="25"
            startAngle="-45"
            endAngle="225"
            default="12.5"
            @muted="mute"
            :pause="pauseBackgroundMusic"
            @volumeChange="volumeChange"
            :backgroundMusicSrc="backgroundMusic"
          />
        </div>
      </div>
    </v-card>

    <GoBackButton />
  </v-container>
</template>

<script>
import LevelSelector from './LevelSelector.vue'
import HowToPlay from '../HowToPlay.vue'
import AudioPlayer from '../AudioPlayer.vue'
import GoBackButton from '../../components/GoBackButton.vue'
import RoundVolumeControl from './RoundVolumeControl.vue'
import Utils from '../../util/Utils'
export default {
  components: {
    LevelSelector,
    HowToPlay,
    AudioPlayer,
    GoBackButton,
    RoundVolumeControl
  },

  props: {
    levels: {
      type: Array,
      default: undefined
    },
    selectedLevel: { type: String, default: '' },
    i18n_instructionsKey: {
      type: String,
      default: 'sentenceFormingInstruction'
    },
    currentQuestionNumber: { type: Number, required: true },
    totalNumberOfQuestions: { type: Number, required: true },
    correctCount: { type: Number, required: true },
    wrongCount: { type: Number, required: true },
    displaySubmit: { type: Boolean, required: true, default: true },
    displayNextQuestionBtn: { type: Boolean, required: false, default: false },
    displayPreviousQuestionBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    colorScheme: { type: String, default: undefined },
    showAudioSetting: { type: Boolean, default: false },
    soundEffects: { type: Boolean, default: true },
    customHeader: { type: String, default: undefined },
    pauseBackgroundMusic: { type: Boolean, default: false },
    backgroundMusic: {
      type: String,
      default: Utils.audioByPath('AsimJayJayKarTune.mp3')
    }
  },
  mounted() {
    console.log(this.backgroundMusic)
  },
  data() {
    return {
      sound: { muted: false, volume: 0.125 }
    }
  },
  methods: {
    restartWithSelectedLevel(level) {
      this.$emit('onLevelSelected', level)
    },
    submitAnswer(level) {
      this.$emit('onSubmitAnswer', level)
    },
    goToNextQuestion(level) {
      this.$emit('onNextQuestion', level)
    },
    goToPreviousQuestion(level) {
      this.$emit('onPreviousQuestion', level)
    },
    volumeChange(volume) {
      this.sound = { ...this.sound, volume: volume / 100 }
    },
    mute() {
      this.sound = { ...this.sound, muted: !this.sound.muted }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/global.scss';

.quizShell {
  margin-top: 50px;
  padding: 10px;
  max-width: 45%;
  @include xl {
    max-width: 35%;
  }
  @include lg {
    max-width: 40%;
  }
  @include xs {
    max-width: 98%;
  }
  @include sm {
    max-width: 80%;
  }
}
.quizCard {
  width: 100%;

  &__header {
    background-color: #f5f5f5;
    padding: 14px;
    text-align: center;
    display: block;
    height: 60px;

    &--questionCounter {
      display: flex;
      align-items: center;
      float: left;
      height: 100%;
    }
  }
}
.gameInfo {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  color: white !important;

  &--scoreCardNormal {
    border-right: solid white;
    padding-right: 16px;
  }

  &--settingsMobile {
    position: absolute;
    right: 0;
    top: -45px;
    padding: 4px;
    border-top-left-radius: 20px;
    align-items: center;
    display: flex;
    height: 44px;
  }

  &--settingsNormal {
    display: flex;
    align-items: center;
  }
}
.answerSlot {
  background: #f8f8f8;
  border-bottom-right-radius: 25px;
  border-top: 1px solid lightgray;
}
</style>
